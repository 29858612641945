<template>
  <div class="tb-width-full">
    <div class="thumb">
      <img src="@/assets/images/service1.png">
    </div>
    <div class="content">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Service'
};
</script>

<style scoped lang="scss"></style>
